import useFunnelStore from "~/stores/funnel";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return

  const store = useFunnelStore()

  if (to.query.coupon) {
    await store.applyCoupon({ code: to.query.coupon.toString(), type: 'coupon' })
  } else if (to.query.code_parrain) {
    await store.applyCoupon({ code: to.query.code_parrain.toString(), type: 'sponsor' })
  }

  // Remove code_parrain or coupon from the URL
  if (to.query.coupon || to.query.code_parrain) {
    const query = { ...to.query }
    delete query.coupon
    delete query.code_parrain
    return { path: to.path, query }
  }
})